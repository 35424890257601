import { useState } from 'react'
import { useNavigate } from 'react-router'
import './Question.css'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

const Question = ({
  currQues,
  setCurrQues,
  questions,
  options,
  correct,
  setScore,
  score,
  setQuestions,
}) => {
  const [selected, setSelected] = useState()
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  const handleSelect = (i) => {
    if (selected === i && selected === correct) return 'select'
    else if (selected === i && selected !== correct) return 'wrong'
    else if (i === correct) return 'select'
  }

  const handleCheck = (i) => {
    setSelected(i)
    if (i === correct) setScore(score + 1)
    setError(false)
  }

  const handleNext = () => {
    if (currQues > 8) {
      navigate('/result')
    } else if (selected) {
      setCurrQues(currQues + 1)
      setSelected()
    } else setError('Please select an option first')
  }

  const handleQuit = () => {
    setCurrQues(0)
    setQuestions()
  }

  const decodeHTMLEntities = (text) => {
    var textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
  }

  return (
    <div className='question'>
      <h1 className='questionCounter'>Question {currQues + 1} :</h1>

      <div className='singleQuestion'>
        <h2 className='currentQuestion'>
          {decodeHTMLEntities(questions[currQues].question)}
        </h2>

        <div className='options'>
          <div>{error && <ErrorMessage>{error}</ErrorMessage>}</div>
          {options &&
            options.map((i) => (
              <button
                className={`singleOption  ${selected && handleSelect(i)}`}
                key={i}
                onClick={() => handleCheck(i)}
                disabled={selected}
              >
                {decodeHTMLEntities(i)}
              </button>
            ))}
        </div>
        <div className='controls'>
          <div className='buttoncontainer'>
            <div className='small-button-background-quit'>
              <a href='/'>
                <button className='small-button' onClick={() => handleQuit()}>
                  ×
                </button>
              </a>
            </div>
          </div>
          <div className='buttoncontainer'>
            <div className='small-button-background-next'>
              <button className='small-button' onClick={handleNext}>
                {currQues > 9 ? '↵' : '→'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Question
