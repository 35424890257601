const ErrorMessage = ({ children }) => {
  return (
    <div
      style={{
        padding: 10,
        marginBottom: 10,
        borderRadius: 4,
        backgroundColor: 'red',
        opacity: 0.5,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000000',
      }}
    >
      {children}
    </div>
  )
}

export default ErrorMessage
