import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import './Result.css'

const Result = ({ name, score }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!name) {
      navigate('/')
    }
  }, [name, navigate])

  return (
    <div className='result'>
      <span className='score'>Final Score : {score}</span>
      <div className='buttoncontainer'>
        <div className='small-button-background-restart'>
          <a href='/'>
            <button className='small-button'>∞</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Result
