import { MenuItem, TextField } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import Categories from '../../Data/Categories'
import './Home.css'

const Home = ({ name, setName, fetchQuestions }) => {
  const [category, setCategory] = useState('')
  const [difficulty, setDifficulty] = useState('')
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  const handleSubmit = () => {
    if (!category || !difficulty || !name) {
      setError(true)
      return
    } else {
      setError(false)
      fetchQuestions(category, difficulty)
      navigate('/quiz')
    }
  }

  return (
    <div className='content'>
      <div className='settings'>
        {/* <span style={{ fontSize: 50 }}>Settings</span> */}
        <div className='settings__select'>
          {error && <ErrorMessage>Please Fill everything</ErrorMessage>}
          <TextField
            style={{ marginBottom: 25 }}
            label='Enter Your Name'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            select
            label='Select Category'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            variant='outlined'
            style={{ marginBottom: 30 }}
          >
            {Categories.map((cat) => (
              <MenuItem key={cat.category} value={cat.value}>
                {cat.category}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label='Select Difficulty'
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
            variant='outlined'
            style={{ marginBottom: 30 }}
          >
            <MenuItem key='Easy' value='easy'>
              Easy
            </MenuItem>
            <MenuItem key='Medium' value='medium'>
              Medium
            </MenuItem>
            <MenuItem key='Hard' value='hard'>
              Hard
            </MenuItem>
          </TextField>
          <div className='buttoncontainerhome'>
            <div className='small-button-backgroundhome'></div>
            <button className='small-buttonhome' onClick={handleSubmit}>
              GO
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
