import './footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <a
        href='https://raffaelli.studio'
        target='_blank'
        className='footer'
        rel='noreferrer'
      >
        Raffaelli.Studio
      </a>
    </div>
  )
}

export default Footer
