import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'

export const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#3f51b5',
      contrastText: 'rgba(255,255,255,0.66)',
    },
    secondary: {
      main: '#f50057',
      contrastText: 'rgba(255,255,255,0.66)',
    },
    text: {
      primary: 'rgba(255,255,255,0.66)',
      secondary: 'rgba(255,255,255,0.44)',
      disabled: 'rgba(255,255,255,0.22)',
      hint: 'rgba(255,255,255,0.33)',
    },
    background: {
      paper: '#424242',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Metrophobic',
    },
    h2: {
      fontFamily: 'Metrophobic',
    },
    h3: {
      fontFamily: 'Metrophobic',
    },
    overline: {
      fontFamily: 'Metrophobic',
    },
    caption: {
      fontFamily: 'Metrophobic',
    },
    button: {
      fontFamily: 'Metrophobic',
    },
    body2: {
      fontFamily: 'Metrophobic',
    },
    body1: {
      fontFamily: 'Metrophobic',
    },
    subtitle2: {
      fontFamily: 'Metrophobic',
    },
    subtitle1: {
      fontFamily: 'Metrophobic',
    },
    h6: {
      fontFamily: 'Metrophobic',
    },
    h5: {
      fontFamily: 'Metrophobic',
    },
    h4: {
      fontFamily: 'Metrophobic',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)
